body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  width: 100vw !important; /* Full viewport width */
  max-width: 100% !important; /* Prevent accidental shrinking */
  overflow-x: hidden;
}

.toolbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: black ;
  color: white;
  padding: 10px 0;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.toolbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.toolbar li {
  margin: 0 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

@media (max-width: 412px) {
  .toolbar li {
    margin: 0 5px;
    font-size: 14px;
  }
}

.toolbar a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-section {
  background: black;
  color: white;
}


.about-section {
  background: black;
  color: white;
}

.contact-section {
  background: black;
  color: white;
}

/* Fix for scroll offset under fixed navbar */
.section {
  scroll-margin-top: 80px; /* Adjust this value to match your navbar height */
}

@media (max-width: 768px) {
  .section {
    scroll-margin-top: 60px; /* Smaller navbar height on mobile */
  }
}
