.home-page {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.home-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  z-index: 2;
  width: 100%;
  padding: 20px;
}



.home-page img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.text-overlay h1 {
  padding-left: 20px;
  
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Puts the video behind the text */
}
