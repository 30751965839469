/* General Styles */
body {
  background-color: black;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  color: white;
}

.container1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

.aboutus-page.column {
  padding: 10px;
}

.service-box {
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
  margin-bottom: 20px;
}

.aboutus-page .service-box {
  display: flex;
  flex-direction: column;
  min-height: 100px; /* Ensures a minimum height */
  height: auto; /* Allows content to define height */
  flex-grow: 1; /* Expands if needed */
}

.service-box h3 {
  font-size: 22px;
  color: white; 
  margin-bottom: 10px;
}

.service-box p {
  font-size: 18px;
  color: white;
  line-height: 1.6;
}

p {
  margin-bottom: 20px;
  font-size: 18px;
}

h3 {
  margin-bottom: 10px;
}

/* Responsive Adjustments */

/* For Tablets and Smaller Screens (max-width: 768px) */
@media (max-width: 768px) {
  h1 {
    font-size: 26px; /* Slightly smaller heading */
  }

  .container1 {
    grid-template-columns: 1fr 1fr; /* Two columns for tablets */
    gap: 15px; /* Smaller gap between columns */
    padding: 15px; /* Reduce padding */
  }

  .service-box {
    padding: 15px; /* Reduce padding inside service boxes */
  }

  .service-box h3 {
    font-size: 20px; /* Slightly smaller font size for headings */
  }

  .service-box p {
    font-size: 16px; /* Smaller text size for paragraphs */
  }
}

/* For Mobile Screens (max-width: 480px) */
@media (max-width: 480px) {
  h1 {
    font-size: 22px; /* Smaller heading for mobile screens */
  }

  .container1 {
    grid-template-columns: 1fr; /* Single column layout for mobile */
    gap: 10px; /* Smaller gap between columns */
    padding: 10px; /* Further reduce padding */
  }

  .service-box {
    padding: 10px; /* Further reduce padding inside service boxes */
    border-radius: 5px; /* Smaller border radius for mobile */
  }

  .service-box h3 {
    font-size: 18px; /* Smaller heading for mobile */
  }

  .service-box p {
    font-size: 14px; /* Smaller paragraph text for mobile */
  }
}

@media (max-width: 360px) and (max-height: 740px) {
  .aboutus-page {
    padding-top: 100px !important; /* Increase padding to ensure heading is visible */
    scroll-margin-top: 100px !important; /* Adjust scroll margin to align properly */
  }
}

@media (max-width: 540px) and (max-height: 720px) {
  .aboutus-page {
    padding-top: 100px !important; /* Increase padding to ensure heading is visible */
    scroll-margin-top: 100px !important; /* Adjust scroll margin to align properly */
  }
}
